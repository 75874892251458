import React from "react"
import styled from "styled-components"
import Seo from "src/components/SEO"
import { PrimaryTitle, TertiaryTitle } from "src/components/Typography"
import BaseContainer from "src/components/Base/BaseContainer"

const PrivacyPolicyPage = () => {
  return (
    <>
      <Seo title="Privacy policy" />
      <PageHeader>
        <PrimaryTitle as="h1">Privacy policy</PrimaryTitle>
      </PageHeader>
      <Container narrow>
        <Paragraph>
          THIS PAGE INFORMS YOU OF OUR POLICIES REGARDING THE COLLECTION, USE
          AND DISCLOSURE OF PERSONAL INFORMATION WE RECEIVE FROM USERS OF THE
          SITE.
        </Paragraph>
        <Paragraph>
          We use your Personal Information only for providing and improving the
          Site. By using our Site you agree to the collection and use of
          information in accordance with this policy.
        </Paragraph>

        <Headline>Information collection and use</Headline>
        <Paragraph>
          While using our Site we may ask you to provide us with certain
          personally identifiable information which can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to: your name; Personal Information; Log Data. Like many
          site operators, we collect information that your browser sends
          whenever you visit our Site.
        </Paragraph>

        <Headline>Log data</Headline>
        <Paragraph>
          This Log Data may include information such as your computers Internet
          Protocol & IP address; browser type; browser version; the pages of our
          Site that you visit; the time and date of your visit; the time spent
          on those pages and other statistics. In addition we may use third
          party services such as Google Analytics that collect, monitor and
          analyze this … The Log Data section is for businesses that use
          analytics or tracking services in websites or apps, like Google
          Analytics.
        </Paragraph>

        <Headline>Communications</Headline>
        <Paragraph>
          We may use your Personal Information to contact you with newsletters;
          marketing or promotional materials and other information.
        </Paragraph>

        <Headline>Cookies</Headline>
        <Paragraph>
          Cookies are files with small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your computers hard drive. Like many websites,
          we use “cookies” to collect information. You can instruct your browser
          to refuse all cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not be able to use some
          portions of our Site.
        </Paragraph>

        <Headline>Security</Headline>
        <Paragraph>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security due to the nature of the
          process.
        </Paragraph>

        <Headline>Changes to this privacy policy</Headline>
        <Paragraph>
          This Privacy Policy is effective as of March 31st, 2018 and will
          remain in effect except with respect to any changes in its provisions
          in the future, which will be in effect immediately after being posted
          on this page.
        </Paragraph>

        <Paragraph>
          We reserve the right to update or change our Privacy Policy at any
          time and you should check this Privacy Policy periodically. Your
          continued use of the Service after we post any modifications to the
          Privacy Policy on this page will constitute your acknowledgment of the
          modifications and your consent to abide and be bound by the modified
          Privacy Policy.
        </Paragraph>

        <Paragraph>
          If we make any material changes to this Privacy Policy, we will notify
          you either through the email address you have provided us, or by
          placing a prominent notice on our Site.
        </Paragraph>

        <Headline>Contact us</Headline>
        <Paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a href="mailto:info@animainternational.org">
            info@animainternational.org
          </a>
        </Paragraph>
      </Container>
    </>
  )
}

const PageHeader = styled.header`
  padding: 250px 0 100px;
  text-align: center;
`

const Container = styled(BaseContainer)`
  margin-bottom: 205px;
`

const Paragraph = styled.p`
  margin-bottom: 2em;
`

const Headline = styled(TertiaryTitle)`
  margin-bottom: 0;
`

export default PrivacyPolicyPage
